<template>
  <div class="">
    <div class="">
      <div class="">
        <b-container class="mt-5">
          <b-row>
            <b-col xl="12" class="card ms-auto me-auto">
              <table class="table table-striped">
                <tr v-for="d in userData" :key="d.key">
                  <td>{{ d.key }}</td>
                  <td>{{ d.value }}</td>
                </tr>
              </table>

              <h4>JWT</h4>
              <pre
                cols="64"
                style="width: 640px; white-space: pre-wrap; overflow: auto"
              >
                {{ jwt }}
              </pre>

              <h4>Org Data</h4>
              <pre
                cols="64"
                style="width: 640px; white-space: pre-wrap; overflow: auto"
              >
                {{ orgData }}
              </pre>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </div>
  </div>
</template>

<script>
// import Amplify from 'aws-amplify'

export default {
  name: 'WhoAmI',
  components: {},
  data() {
    return {}
  },
  mounted() {},
  computed: {
    loggedInUser() {
      return this.$store.getters.loggedInUser
    },
    jwt() {
      return this.loggedInUser.signInUserSession.idToken.jwtToken
    },
    payload() {
      return this.loggedInUser.signInUserSession.idToken.payload
    },
    organizationName() {
      return this.$store.getters.userInfo.organizationName
    },
    orgData() {
      return JSON.parse(window.localStorage.orgData)
    },
    userData() {
      if (this.loggedInUser) {
        return [
          { key: 'environment', value: this.payload.environment },
          { key: 'pulsUsername', value: this.payload.pulsUsername },
          { key: 'cognito:username', value: this.payload['cognito:username'] },
          { key: 'email', value: this.payload.email },
          { key: 'organizationName', value: this.payload.organizationName },
          { key: 'is admin', value: this.payload.userIsAdmin },
          { key: 'is puls admin', value: this.payload.userIsPulsAdmin },
          {
            key: 'organizationName localStorage',
            value: localStorage.organizationName
          },
          {
            key: 'organizationFullName',
            value: this.payload.organizationFullName
          },

          { key: 'groups', value: this.payload['cognito:groups'] },
          {
            key: 'group permissions',
            value: this.payload.groupPermissions
              ? JSON.parse(this.payload.groupPermissions)
              : 'none'
          },
          { key: 'userPoolId', value: this.loggedInUser.pool.userPoolId },
          { key: 'clientId', value: this.loggedInUser.pool.clientId }
        ]
      } else {
        return []
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
